var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loader',{attrs:{"loading":_vm.loading}},[_c('v-container',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.back"))+" ")]),(!_vm.loading)?_c('v-flex',{attrs:{"sm12":"","md12":""}},[_c('v-card',{staticClass:"mt-2 jobs-card",attrs:{"flat":""}},[_c('div',{staticClass:"jobs-title"},[_c('div',{staticClass:"sub-header"},[_c('h3',{},[_vm._v(_vm._s(_vm.job.title))]),_c('span',{staticClass:"text-sm-left ml-0 pl-0 clear"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-map-marker ")]),_vm._v(" "+_vm._s(_vm.job.location)+" ")],1)]),_c('div',[(_vm.job.referal)?_c('div',[_c('v-btn',{staticClass:"secondary",attrs:{"to":{
                  name: 'apply-job',
                  params: { id: _vm.job._id },
                  query: { emp_id: _vm.job.referal, platform: _vm.job.platform },
                }}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.apply_now"))+" ")])],1):_c('div',[_c('v-btn',{staticClass:"secondary",attrs:{"dark":"","to":{ name: 'apply-job', params: { id: _vm.job._id } }}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.apply_now"))+" ")])],1)])]),_c('v-card-text',{staticClass:"px-0"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"px-3 py-1",attrs:{"xs12":"","sm11":""}},[(_vm.job.end_date)?_c('span',{staticClass:"ml-0 pl-0 clear"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-calendar-today ")]),_vm._v(" Apply before "+_vm._s(_vm._f("dateFilter")(_vm.job.end_date))+" ")],1):_vm._e(),_c('span',{staticClass:"ml-0 pl-4 clear",attrs:{"disabled":"","flat":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-timelapse ")]),_vm._v(" "+_vm._s(_vm._f("jobType")(_vm.job.job_type))+" ")],1),_c('span',{staticClass:"ml-0 pl-4 clear",attrs:{"disabled":"","flat":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cash-100 ")]),_vm._v(" "+_vm._s(_vm.job.salary || _vm.$vuetify.lang.t("$vuetify.negotiable"))+" ")],1)]),(_vm.job.link)?_c('v-flex',[_c('v-tooltip',{staticClass:"text-xs-right",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.job.link),expression:"job.link"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.clipboardSuccessHandler),expression:"clipboardSuccessHandler",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.clipboardErrorHandler),expression:"clipboardErrorHandler",arg:"error"}],staticClass:"text-xs-right",attrs:{"slot":"activator","color":"primary","dark":"","icon":""},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-clipboard-text ")])],1)]}}],null,false,2061247764)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.copy_text"))+" ")])])],1):_vm._e()],1)],1)],1),(_vm.job)?_c('v-card',{staticStyle:{"background":"#ffffff"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-3",attrs:{"xs12":""},domProps:{"innerHTML":_vm._s(_vm.job.description)}}),_c('v-flex',{staticClass:"mt-5",attrs:{"xs12":""}},[_c('v-layout',{staticClass:"py-3 text-xs-center",attrs:{"row":"","justify-center":"","align-center":""}},[(_vm.job.referal)?_c('div',[_c('v-btn',{staticClass:"secondary",attrs:{"dark":"","to":{
                      name: 'apply-job',
                      params: { id: _vm.job._id },
                      query: { emp_id: _vm.job.referal, platform: _vm.job.platform },
                    }}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.apply_now"))+" ")])],1):_c('div',[_c('v-btn',{staticClass:"secondary",attrs:{"dark":"","to":{ name: 'apply-job', params: { id: _vm.job._id } }}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.apply_now"))+" ")])],1)])],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }